<template>
  <div>
    <div>
      <div>
        <h2 class="titlePage_subtitle titlePage">Midrails</h2>
        <Form v-slot="{ errors }" action="" class="form" >
<!--          v-on:submit.prevent-->
          <div class="bordered">
            <div class="">
              <div class="row">
                <div class="col-1">

                </div>
                <div class="col col-12 col-md-5">
                  Position
                </div>
                <div class="col col-12 col-md-6">
                  Colour
                </div>
              </div>

              <div class="row"
                   v-for="(item, index) in settings.values"
                   :key="index"
              >
                <div class="col col-1">
                  <label class="form__label">
                    <input type="checkbox" hidden class="form__checkbox"
                           @change="triggerDisabled(item)"
                           :disabled="item.required"
                           :checked="item.selected"
                    >
                    <span class="form__checkbox_icon"></span>
                  </label>
                </div>

                <div class="col col-12 col-md-5">
                  <label class="form__label">
                    <Field type="text" class="form__input"
                           name="position"
                           :disabled="!item.selected"
                           v-bind:key="index"
                           v-model="item.position"
                           @input="validateGaps()"
                           :rules="{
                           required: true,
                           min_value:validateMin(item, index),
                           max_value:validateMax(item, index),
                         }"
                    />
                  </label>

                </div>

                <div class="col col-12 col-md-6">
                  <div class="row align-middle" v-if="item.selected && item.colour">

                    <div class="col-8">
                      <p class="text_default">{{item.colour.name}} <span v-if="item.colour.is_same_as_frame"> (same as frame)</span> </p>
                    </div>
                    <div class="col-4"
                         @click="openColourSelection(item)"
                    >
                      <div class="colours colour_box colours_small">
                      <img class="box__img" :src="item.colour.image" alt="color.colour_name" v-if="item.colour.image">
                      <p v-else-if="item.colour.hexcode">
                        <svg-icon name="palette" class="large_icon" :style="{color: item.colour.hexcode}"/>
                      </p>
                      <p v-if="!item.colour.image && !item.colour.hexcode">
                        <svg-icon name="palette" class="large_icon"/>
                      </p>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul>
            <li v-for="group in errors">
              <ul>
                <li class="form__error">{{group}}</li>
              </ul>
            </li>
            <li v-for="err in gapsValidation">
              <ul>
                <li class="form__error">{{err}}</li>
              </ul>
            </li>
          </ul>

          <p class="text_center" :class="{'text_red': view_inside}">Please note: Looking from {{view_inside ? 'inside' : 'outside'}}<br>
            Position is measured from bottom left of the screen to centre of Midrail
          </p>

        </Form>
        <template v-if="actionLinksPortalTarget">
          <portal :to="actionLinksPortalTarget">
            <button type="submit" @click.prevent="save" class="btn btn_transparent"><svg-icon name="solid/check" /> Done</button>
          </portal>
        </template>
        <template v-else>
          <button type="submit" @click.prevent="save" class="btn btn_default"><svg-icon name="solid/check" /> Done</button>
        </template>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <select-colors v-show="selectColours"
                     @onSelect="setColour"></select-colors>
    </transition>

  </div>
</template>

<script>
    import {mapState, mapGetters} from 'vuex';
    import options from "../../../store/modules/order/options";
    import {defineAsyncComponent} from "vue";
    const SelectColors = defineAsyncComponent(() => import('../../modals/OptionColours'));

    export default {
        name: "Midrails",

        data() {
            return {
                view_inside: false,
                gapsValidation: []
            }
        },

        components: {
            SelectColors,
        },

        props: ['options', 'checkIsSetPackage', 'actionLinksPortalTarget'],

        methods: {
            closePopup() {
                this.$store.commit('options/closeAvailable');
            },

            isSelected(index){
              return index < this.selected.length
            },


            triggerDisabled(obj) {
                obj.selected = !obj.selected;
                obj.position = '';
                this.$store.commit('orderPosition/setOption', { option: 'MIDR', selection: this.settings});
            },

            save() {
                /*this.$validator.validate().then(valid => {*/
                    if (this.validateGaps()) {
                        this.$store.commit('orderPosition/setOption', { option: 'MIDR', selection: this.settings});
                        this.$store.commit('orderPosition/optionNext', 'MIDR');
                    }
              /*  })*/
            },

            validateGaps(){
              this.gapsValidation = [];

              var vertical = [];
              vertical["Bottom"] = 0;

              var v = this.settings.values.slice().reverse(),
                  has_required = false;


              _.forEach(v, function(i, k) {
                 if (i.selected)
                     vertical["Midrail " + (k + 1)] = i.position;

                 has_required = has_required || i.required
              });
              vertical["Top"] = this.order.htl;

              if (!has_required)
                  return true;

              var prev = '';
              _.each(Object.keys(vertical), (i, k) => {

                  if (prev == '')
                  {
                      prev = i;
                      return;
                  }

                  if (vertical[i] - vertical[prev] < this.settings.settings.gap_min)
                  {
                      this.gapsValidation.push('Gap between ' + prev + ' and ' + i + ' is less than ' + this.settings.settings.gap_min + 'mm')
                  }

                  if (vertical[i] - vertical[prev] > this.settings.settings.gap_max)
                  {
                      this.gapsValidation.push('Gap between ' + prev + ' and ' + i + ' is longer than ' + this.settings.settings.gap_max + 'mm')
                  }

                  prev = i;
              });


              return this.gapsValidation.length == 0;
            },

            validateMin(item, index) {
                var lowend = 0;

                if (index + 1 < this.settings.values.length)
                {
                    lowend = this.settings.values[index + 1].position;
                }

                return Number(lowend) + Number(this.settings.settings.gap_min);
            },
            validateMax(item, index) {
                var highend = this.order.htl;

                if (index > 0)
                {
                    if (this.settings.values[index - 1].selected && this.settings.values[index - 1].position)
                      highend = this.settings.values[index - 1].position;
                }

                return highend - this.settings.settings.gap_min;
            },

            openColourSelection(item){

                if (!item.selected)
                    return;

                if (this.settings.settings.colour_group)
                    this.$store.commit('optionColours/colourGroup', this.settings.settings.colour_group);

                this.current_item = item;

                this.$store.commit('optionColours/openModal', true);
                this.$store.commit('optionColours/getColors');
            },

            setColour(c){
                //let c = this.$store.getters['optionColours/selectedColour'];

                if (c) {
                    this.current_item.colour = c.colour;
                    if (!this.$store.commit('options/updateColour', {option: 'MIDR', colour: c.colour})) {
                        this.openColourSelection(this.current_item);
                        return;
                    }
                }
                this.current_item = null;
            },

        },

        computed: {
            checkOrder() {
                if (this.prefer_entry === 'drop') {
                    return 1
                }
                else return 2;
            },
            ...mapState('orderPosition', ['orderPosition']),
            ...mapState('options', ['name', 'price', 'modalView']),
            ...mapGetters({
                order: 'orderPosition/orderPosition',
                selectColours: 'optionColours/openModal',
            }),

            settings() {
                return this.$store.getters['orderPosition/getCurrentOptionSettings'];
            },

        },

        mounted() {
        },

    }
</script>

<style scoped lang="scss">
  @import '../../../../src/assets/scss/utils/vars';

  .form {

    &__input {
      border-color: $navy;
    }

    .bordered {
      border: 1px solid $navy;
      padding: 10px 0;
      margin: 20px 0;
    }

    &__labelTitle {
      color: $navy
    }

    .justify-content-center, .justify-content-between {
      display: flex;
      margin: 10px;
    }

    .btn {
      margin: 20px 0 0;
      max-width: none;
      width: 100%;
    }

  }

  .modal__close {
    color: $green;
  }


</style>
